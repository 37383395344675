import React from "react";
import { Form, Button } from "react-bootstrap";
import emailjs from "emailjs-com";
import { Row, Col, Card, Image, Modal } from "../../bootstrap.js";
import { SubmitButton } from "../SubmitButton/SubmitButton.js";
import "./contact.css";
import { useState } from "react";

export const Email = (props) => {
  const [validated, setValidated] = useState("");

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const { name, email, subject,message } = e.target.elements;

      const templateParams = {
        from_name: name.value,
        from_email: email.value,
        from_subject: subject.value,
        from_message: message.value,
      };
      console.log(templateParams.from_name);

      await emailjs
        .send(
          "service_qv3fq1a",
          "template_d09tq3t",
          templateParams,
          "YkS6o_EoSAdsKQX2g"
        )
        .then((response) => {
          console.log("Email sent successfully!", response);
          alert("Thank you for Contacting The Minkara COMBINE Lab!");

          // Optionally, you can reset the form here
          e.target.reset();
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    }
    setValidated(true);
  };

  return (
    <div>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="contactForm "
      >
        <Form.Group controlId="name" className="mb-3">
          <Form.Label className="formLabel">Name</Form.Label>
          {/* <div className="fullName">
            <div className="nameCont"> */}
          <Form.Control
            type="text"
            name="name"
            // placeholder="First Name"
            // aria-placeholder="Full Name"
            required
            className=" box3D"
          />
          <Form.Control.Feedback type="invalid">
            Name is required.
          </Form.Control.Feedback>
          {/* <p aria-hidden={true}>First</p>
              <Form.Control.Feedback type="invalid" className="feedback">
                First name is required.
              </Form.Control.Feedback>
            </div>
            <div className="nameCont">
              <Form.Control
                type="text"
                name="lastName"
                // placeholder="Last Name"
                // aria-placeholder="Full Name"
                required
                className="box3D halfName"
                aria-label="Last"
              />
              <p aria-hidden={true}>Last</p>
              <Form.Control.Feedback type="invalid" className="feedback">
                Last name is required.
              </Form.Control.Feedback>
            </div>
          </div> */}
        </Form.Group>

        <Form.Group controlId="email" className="mb-3">
          <Form.Label className="formLabel">Email</Form.Label>
          <Form.Control type="email" name="email" required className="box3D" />
          <Form.Control.Feedback type="invalid">
            Email is required.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="subject" className="mb-3">
          <Form.Label className="formLabel">Subject</Form.Label>
          <Form.Control type="text" name="subject" required className="box3D" />
          <Form.Control.Feedback type="invalid">
            Subject is required.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="message" className="mb-3">
          <Form.Label className="formLabel">Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            name="message"
            maxlength="200"
            required
            className="box3D"
          />
          <Form.Control.Feedback type="invalid">
            Message is required.
          </Form.Control.Feedback>
        </Form.Group>

        <Row className="bottomContact gy-2">
          <SubmitButton></SubmitButton>
        </Row>
      </Form>
    </div>
  );
};
