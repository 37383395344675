import { Row, Col, Image } from "../../bootstrap.js";
import "./research.css";
import "./Projects/projects.css";
import { Methods2 } from "./Methods2.js";
import { Projects2 } from "./Projects/Projects2.js";
import { ProjectsDepth2 } from "./Projects/ProjectsDepth2.js";
import { useMediaQuery } from "usehooks-ts";
import { useState } from "react";

export function Research2() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const diseases = [
    "Neonatal Respiratory Distress Syndrome (RDS)",
    "Acute Respiratory Distress Syndrome (ARDS)",
    "Pulmonary Alveolar",
    "Proteinosis (PAP)",
    "Idiopathic Pulmonary Fibrosis (IPF)",
    "Sarcoidosis",
    "Asthma",
    "Bacterial Pneumonia",
  ];

  const isMobile = useMediaQuery("(max-width: 992px)");

  return (
    <div>
      {isMobile ? (
        /* Mobile Version */
        <Col className="research">
          <div className="researchHero">
            <h3 className="redHeaderResearch">Research</h3>
            <Col xs={12} className="imgOverview">
              <Image
                src="/images/Research/laboverview.png"
                className="img-fluid"
                alt="Diagram of pulmonary surfactant zoomed in from the lung."
              />
            </Col>
          </div>

          <Col className="intro">
            <div className="firstSecResearch">
              <h4 className="blackHeaderResearchMain">Focus</h4>
              <Col xs={11} className="researchRightSide">
                <p className="researchFocus">
                  We model pulmonary surfactant (PS) at the molecular level using various computational techniques to understand the different components of pulmonary surfactant to advance human health.
                </p>
              </Col>
            </div>
          </Col>

          <section className="projects2">
            <ProjectsDepth2 index={index} handleSelect={handleSelect} />
          </section>

          <section className="methods">
            <Methods2 setIndex={setIndex} />
          </section>
        </Col>
      ) : (
        /* Desktop Version */
        <Col className="research">
          <div className="researchHero">
            <h3 className="redHeaderResearch">Research</h3>
            <Col xs={12} className="imgOverview">
              <Image
                src="/images/Research/laboverview.png"
                className="img-fluid"
                alt="Diagram of pulmonary surfactant zoomed in from the lung."
              />
            </Col>
          </div>

          <Col className="intro">
            <div className="firstSecResearch">
              <div className="researchSideBar">
                <h4 className="blackHeaderResearchMain">Focus</h4>
              </div>
              <Col xs={11} className="researchRightSide">
                <p className="researchFocus">
                  We model pulmonary surfactant (PS) at the molecular level using various computational techniques to understand the different components of pulmonary surfactant to advance human health.
                </p>
              </Col>
            </div>
          </Col>

          <section className="projects2">
            <ProjectsDepth2 index={index} handleSelect={handleSelect} />
          </section>

          <section className="methods">
            <Methods2 setIndex={setIndex} />
          </section>
        </Col>
      )}
    </div>
  );
}
