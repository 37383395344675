import { Form, Button, Row, Col, Image } from "../../bootstrap.js";
import { SubmitButton } from "../SubmitButton/SubmitButton.js";
// import { SocTestimonial } from "./Testimonials/SocTestimonial.js";
import { useState } from "react";
import "./contact.css";
import { ContactHome } from "./ContactHome/ContactHome.js";
import { Email } from "./Email.js";
import { IconButton } from "../Button/IconButton/IconButton.js";
import { useMediaQuery } from "usehooks-ts";

//use auth and GCP STMP servers with nodemailer
export function ContactForm() {
  const isMobile = useMediaQuery("(max-width: 992px)");

  return (
    <div className="contactDiv">
      {/* <h3 className="contactUs">Contact Us</h3> */}
      <Row className="contactRow1 gy-1">
      <Col
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          xxl={6}
          className="contactInfo1"
        >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className="contactInfo"
        >
          <div>
            <h3 className="contactHeaders">EMAIL & PHONE</h3>

            <div className="email contactSec">
              <a href="mailto:m.minkara@northeastern.edu">
                <p>m.minkara@northeastern.edu</p>
              </a>
            </div>
            <p>617.373.2031</p>
          </div>
          <div className="contactSec">
            <h3 className="contactHeaders">OFFICE</h3>
            <a href="https://www.google.com/maps/place/Northeastern+University+Interdisciplinary+Science+and+Engineering+Complex/@42.3377192,-71.0895366,17z/data=!3m1!4b1!4m6!3m5!1s0x89e37a22bfa1d9d7:0xbab99b179dfdea31!8m2!3d42.3377153!4d-71.0869617!16s%2Fg%2F11f_ds1m54?entry=ttu">
              <p>ISEC 325</p>
            </a>

            <a href="https://bioe.northeastern.edu/">
              <p>Department of Bioengineering</p>
            </a>
          </div>
          <div className="contactSec">
            <h3 className="contactHeaders">RELATED LINKS</h3>
            <a href="https://coe.northeastern.edu/people/minkara-mona/#_ga=2.38495139.1283576135.1610572978-967137612.1582053801">
              <p>Dr. Mona Minkara</p>
            </a>
            <a href="https://bioe.northeastern.edu/">
              <p>Faculty and Staff Directory</p>
            </a>
            <a href="https://monaminkara.com">
              <p>www.monaminkara.com</p>
            </a>
          </div>
          <div className="contactSec">
            <h3 className="contactHeaders">SOCIAL MEDIA</h3>
            <div className="contactSoc">
              <a href="https://www.linkedin.com/company/minkara-combine-lab/">
                <i class="bi bi-linkedin"></i>
              </a>
              <a href="https://www.youtube.com/channel/UCYBXlV5HoElWXs5zCKNEtaQ">
                <i class="bi bi-youtube"></i>
              </a>
            </div>
          </div>
        </Col>
        </Col>
        <Col
          className="contactCol1"
          xs={12}
          sm={12}
          md={9}
          lg={5}
          xl={5}
          xxl={5}
        >
          <Email></Email>
        </Col>
      </Row>
    </div>
  );
}
