import React from 'react';
import { Carousel, Row, Col, Image } from '../../bootstrap';
import "./myCarousel.css";
import "../BannerImportant/bannerImportant.css";
import { CarouselPub } from './CarouselPub';

export const MyCarousel = () => {
    return (
    
            <Carousel className="myCarousel carousel-dark">
                <Carousel.Item >
                    <Col>
                    <div className="nprIframe2Border">
                        <iframe
                            src="https://www.npr.org/player/embed/1118521010/1118521011"
                            height="px"
                            frameborder="0"
                            scrolling="no"
                            title="NPR embedded audio player"
                            className="nprIframe2"
                        ></iframe> 
                        <div className="imgDesCar2">
                         <div className="natureCitation nprDesc ">
                                    {/* <p className="nytAuthor">Credit: Kayana Szymczak</p> */}
                                    {/* <p className="nytAuthor">By Kendall Powell</p> */}
                                    <p className="nytAuthor">NPR</p>
                                    <p className="nytDate">Aug 19, 2022</p>
                                </div>
                                <a className="nytReadmore">
                                    <div className="articleCar">
                                    {/* <h4 className="nytName nytTimes">
                                        Chemical modelling with a sense of touch
                                    </h4> */}
                                     <hr className="hrLineFoot2 hrnpr2"></hr>
                                    <h5 className="nytTag">NPR's Mary Louise Kelly talks with Mona Minkara, a professor of bioengineering at Northeastern University who is also blind...</h5>
                                    </div>
                                </a>
                                </div>
                                </div>
                    </Col>
                </Carousel.Item>
                <Carousel.Item>
                    <Col>

                        <a href="https://www.nature.com/articles/d41586-021-02042-3">
                            <div className="nytBorder">
                                <div className="nyt">
                                    <Image src="/images/HomePics/monaNature.png"
                                        className="img-fluid imgCar"
                                        alt="Mona sits at her desk using a braille keyboard.">

                                    </Image>
                                 
                                   

                                </div>
                                <div className="imgDesCar">
                                <div className="natureCitation">
                                    {/* <p className="nytAuthor">Credit: Kayana Szymczak</p> */}
                                    {/* <p className="nytAuthor">By Kendall Powell</p> */}
                                    <p className="nytAuthor">nature</p>
                                    <p className="nytDate">Jul 26, 2021</p>
                                </div>
                                <a className="nytReadmore">
                                    <div className="articleCar">
                                    <h4 className="nytName nytTimes">
                                        Chemical modelling with a sense of touch
                                    </h4>
                                    <hr className="hrLineFoot2"></hr>
                                    <h5 className="nytTag">Computational chemist Mona Minkara simulates the molecular dynamics required for lung function and boosts access for visually impaired chemists.</h5>
                                    </div>
                                </a>
                                {/* <p className="natureLink">nature</p> */}
                                </div>
                            </div>
                        </a>


                    </Col>
                </Carousel.Item>
                <Carousel.Item>
                    <Col>

                        <a href="https://www.nytimes.com/2021/10/22/science/astronauts-disabilities-astroaccess.html">
                            <div className="nytBorder">
                                <div className="nyt">
                                    <Image
                                        src="/images/HomePics/astronauts.png"
                                        className="img-fluid imgCar"
                                        alt="A woman grins at the camera as she floats in a 0 gravity simulation."
                                    ></Image>

                                </div>
                                {/* <div className="newyorktimes"> 
                            <h5>The New York Times</h5>
                            </div> */}
                                <div className="imgDesCar">
                                <div className="nytCitation">
                                    {/* <p className="nytAuthor">By Amanda Morris</p> */}
                                    <p className="nytAuthor">The New York Times</p>
                                    <p className="nytDate">Oct 22, 2021</p>
                                </div>
                                <a className="nytReadmore">
                                    <div className="articleCar">
                                    <h4 className="nytName nytTimes">
                                        A Future for People With Disabilities in Outer Space Takes
                                        Flight
                                    </h4>
                                    <hr className="hrLineFoot2"></hr>
                                    {/* with the goal of proving that they can safely go to space. */}
                                    <h5 className="nytTag">People with different types of disabilities tested their skills and technologies on a zero-gravity research flight...</h5>
                                    </div>

                                </a>
                                {/* <p className="nytLink">The New York Times</p> */}
                                </div>
                            </div>
                        </a>


                    </Col>
                </Carousel.Item>
                <Carousel.Item>
                    <Col>
                        <a href="https://www.youtube.com/watch?v=lxkoDJr77zY">
                            <div className="vidCarousel">
                                <div className="vidCarouselCont">
                               
                                <Image src="/images/HomePics/tumbleTN.jpeg"
                                    className="img-fluid" />
                                     <i class="bi bi-play-circle-fill vidCarIcon"
                                     alt="Video thumbnail with a blue background and a detective underneath a magnifying glass to the right."></i>
                                    </div>
                                    <div className="imgDesCar2">
                                    <div className="carVidHeader">
                                    <p className="nytAuthor">Tumble Media</p>
                                    <p className="nytDate">2024</p>
                                </div>
                             
                                    <a className="nytReadmore">
                                    <div className="articleCar">
                                        <h4 className="nytName nytTimes">
                                        Mona Minkara Contributes to Tumble Science Podcast for Kids
                                        </h4>
                                        <hr className="hrLineFoot2"></hr>
                                        <h5 className="nytTag">I was invited by Tumble Media to contribute to their exciting NSF-funded research project.</h5>
                                        </div>

                                    </a>
                                  {/* <p className="vidLink">YouTube</p> */}
                                </div>
                            </div>
                        </a>

                    </Col>



                </Carousel.Item>
                <Carousel.Item>
                    <Col>

                        <a href="https://www.youtube.com/watch?v=KDYOET2XE3s">
                            <div className="vidCarousel">
                            <div className="vidCarouselCont">
                                <div className="iconBG"></div>
                                <Image src="/images/HomePics/un.png"
                                    className="img-fluid vidImgCar" 
                                    alt="A video thumbnail of Mona speaking at the UN."/>
                                      <i class="bi bi-play-circle-fill vidCarIcon"></i>
                                      </div>
                                      <div className="imgDesCar2">
                                <div className="carVidHeader">
                                    <p className="nytAuthor">Science in Braille | RASIT </p>
                                    <p className="nytDate">2023</p>
                                </div>
                                <div>
                                    <a className="nytReadmore">
                                    <div className="articleCar">
                                        <h4 className="nytName nytTimes">
                                            Mona Minkara Speaks at the United Nations
                                        </h4>
                                        <hr className="hrLineFoot2"></hr>
                                        <h5 className="nytTag">The Science in Braille campaign is a diverse scientific community of expert scientists that share the experience of being blind. </h5>
                                        </div>

                                    </a>
                                  {/* <p className="vidLink">YouTube</p> */}
                                </div>
                                </div>
                            </div>
                        </a>
                    </Col>
                </Carousel.Item>

            </Carousel >
           
        
    );
};

