import { useState } from "react";
import "./privacyPolicy.css";

export function PrivacyPolicy(){


    return (
     
            <div className="privacyPolicy">
        <section>
        <div>
            <header><h2 className="pp">Privacy Policy</h2></header>

<p>Minkara Combine Lab is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website www.minkaracombinelab.com, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the Site.</p></div>
<div>
<h3>Information We Collect</h3>
<p>We do not collect any personally identifiable information from visitors to our website, except when voluntarily provided through the contact form. When you choose to contact us via the contact form, we collect your name, email address, and any other information you provide in your message.</p>

</div>
<div><h3>Use of Contact Form</h3>
<p>We use a contact form on our website to allow visitors to send us messages. When you submit the contact form, the information you provide, including your inputed name and email address, is sent to us via email.js. We use this information solely to respond to your inquiries.</p></div>
<div>
<h3>Use of Cookies and Tracking Technologies</h3>
<p>Our website does not use cookies or any other tracking technologies.</p></div>
<div>
<h3>Third-Party Links</h3>
<p>Our website may contain links to third-party websites such as YouTube. Please note that we are not responsible for the privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p></div>

<div>
<h3>Changes to This Privacy Policy</h3>
<p>We reserve the right to change this Privacy Policy at any time. We will notify you of significant changes to our Privacy Policy by updating the "Last Updated" date at the bottom of this page.</p></div>
<div><h5>Last Updated</h5><p>Jun 13, 2024</p></div>
        </section>
   
        </div>
     

    )
}