import {
  Row,
  Col,
  Card,
  Image,
  Ratio,
  Nav,
  Tab,
  Modal,
  Button,
} from "../../bootstrap.js";
import { researchTitles } from "./pdfContent.js";
import { PDFDescription } from "./PDFDescription.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSolid, faEye } from "@fortawesome/free-solid-svg-icons";
// import { fa-solid, fa-eye } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import "./pdf.css";

export function PDFImage({ content = researchTitles }) {
  // const posters = ["6", "5", "4", "3", "2", "1"];

  const posters = [
    {
      title:
        "Exploration of Surfactant Protein D- Trimannose Complexes Using Computational Techniques",
      id: "1",
    },
    {
      title:
        "Monte Carlo Simulations of Phase Coexistence Pentadecanoic Acid Monolayers",
      id: "2",
    },
    {
      title:
        "Molecular Docking Studies of Surfactant Protein A (SP-A) with surface glycans on pathogens",
      id: "3",
    },
    {
      title:
        "Computationally Investigating the Molecular Mechanism of Surfactant Protein-D's Higher Antiviral Activity at the Molecular Level",
      id: "4",
    },
    // {
    //   title:
    //     "Computational Modeling of the Binding Mechanisms of Surfactant Protein D (SP-D) with glycans.",
    //   id: "5",
    // },
    // {
    //   title: "Conformational changes of surfactant protein B due to the alveolar air/liquid interface",
    //   id: "6",
    // },
  ];
  const [showPdfModal, setShowPdfModal] = useState(false);

  const handleClose = async () => await setShowPdfModal(false);
  const handleShow = async () => await setShowPdfModal(true);

  return (
    <Row className="pdf">
      <Tab.Container defaultActiveKey="researchPublications">
        <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} className="navPdfCol">
          <Nav variant="underline">
            <Nav.Item>
              <Nav.Link
                eventKey="researchPublications"
                className="navUnderlined"
              >
                Research Publications
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="posters" className="navUnderlined">
                Posters
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Tab.Content>
          <Tab.Pane eventKey="researchPublications">
            {content.map((researchTitle, i) => (
              <Row id={`${researchTitle.img}`} className="pdfRow">
                <div className="pdfImage pub3D">
                  <a
                    href={researchTitle.url}
                    download={researchTitle.title}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Image
                      src={`/images/Publication/${researchTitle.img}.jpg`}
                      className="img-fluid pdfBorder"
                      alt="A pdf image of the research paper."
                    />
                  </a>
                </div>
                <Col
                  xs={7}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  xxl={6}
                  className="pubArticleCol"
                >
                  <header>
                    <a
                      href={researchTitle.url}
                      className="pdfLink"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h4 className="title">{researchTitle.title}</h4>
                      {/* <div className="featured">
                        <h4 className="title">{researchTitle.title}</h4>
                        {researchTitle.star === "star" ? (
                          <i className="bi bi-star-fill starColor"></i>
                        ) : (
                          <></>
                        )}
                      </div> */}
                      <h5 className="authors">{researchTitle.author}</h5>
                      <h5 className="h5Cit">{researchTitle.citation}</h5>
                    </a>
                  </header>
                  <div className="pdfLinks">
                    <a
                      href={`/files/${researchTitle.pdf}.pdf`}
                      download={researchTitle.title}
                      className="downloadPdf"
                    >
                      <i
                        className="bi bi-file-earmark-arrow-down"
                        aria-label="Download pdf"
                      ></i>
                    </a>

                    {/* <i
                      className="bi bi-file-earmark-pdf-fill viewerPdf"
                      role="button"
                      onClick={handleShow}
                    ></i>

                    <Modal show={showPdfModal} onHide={handleClose} size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title>{researchTitle.title}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <iframe
                          src={`/files/${researchTitle.pdf}.pdf`}
                          title={researchTitle.title}
                          width="100%"
                          height="500px"
                        ></iframe>
                      </Modal.Body>
                    </Modal> */}
                  </div>
                </Col>
              </Row>
            ))}
          </Tab.Pane>
          <Tab.Pane eventKey="posters">
            {posters.map((poster) => (
              <Row className="postersRow gy-3">
                <Col className="pub3D">
                  <Image
                    src={`/images/Research/Posters/poster${poster.id}.png`}
                    className="img-fluid poster "
                    alt={poster.title}
                  ></Image>
                </Col>
              </Row>
            ))}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Row>
  );
}
