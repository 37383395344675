import {
  Nav,
  Navbar,
  Image,
  Col,
  Offcanvas,
  Row,
  Button,
} from "../../bootstrap.js";
// import { MemberButtonNav } from "../MemberButton/MemberButtonNav.js";
import "./navBar.css";
import { OrgTitle } from "./OrgTitle.js";
import classNames from "classnames";
import { useMediaQuery } from "usehooks-ts";
import { useState } from "react";
import { PillButton } from "../Button/Pill/PillButton.js";

const landingPage = "Home";

//add Galery
const navTitles = [
  { id: "1", title: `${landingPage}` },
  { id: "2", title: "Research" },
  { id: "3", title: "Publications" },
  { id: "4", title: "Group" },
  { id: "5", title: "News" },
  { id: "6", title: "Apply" },
  // { id: "7", title: "Media" },
  { id: "8", title: "Contact" },
];

export function NavBar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isMobile = useMediaQuery("(max-width: 1030px)");
  const isMobile2 = useMediaQuery("(max-width: 992px)");
  return (
    <section>
      <section className="navbarPositioning" role="banner">
        <Navbar.Brand>
          {/* <div className="logoCont">
            <OrgTitle></OrgTitle> */}
          <a href="/">
            <Image
              src="/images/logo/combineLogoLatest.png"
              href="/Home"
              className="img-fluid logo"
              alt="Minkara Combine Lab: Molecular Detectives with the logo of a magnifying glass over some proteins to the right."
            ></Image>
          </a>
          {/* </div> */}
        </Navbar.Brand>
        <div className="dmTog">
          <Navbar expand="lg" className={classNames("bg-body-primary", "nav1")}>
            {/* {isMobile2 ? <DarkLightMode></DarkLightMode> : <></>} */}
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={handleShow}
            />
            <Offcanvas show={show} onHide={handleClose} responsive="lg">
              <Offcanvas.Body>
                <Nav>
                  {navTitles.map((navTitle) => (
                    <>
                      {navTitle.title === landingPage ? (
                        <Nav.Link key={navTitle.id} href="/">
                          {navTitle.title}
                        </Nav.Link>
                      ) : (
                        // : navTitle.title === "Publications" ? (
                        //   <Nav.Link
                        //     key={navTitle.id}
                        //     href="https://monaminkara.com/publications#gsc.tab=0"
                        //   >
                        //     {navTitle.title}
                        //   </Nav.Link>)
                        <Nav.Link key={navTitle.id} href={`/${navTitle.title}`}>
                          {navTitle.title}
                        </Nav.Link>
                      )}
                    </>
                  ))}
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
            {/* {isMobile2 ? <></> : <DarkLightMode></DarkLightMode>} */}
            {/* 
          {isMobile ? <></> : <PillButton></PillButton>} */}
          </Navbar>
        </div>
      </section>
      {/* {isMobile ? <PillButton></PillButton> : <></>} */}
    </section>
  );
}
