import "./Home.css";
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Row, Col } from "../../bootstrap.js";
import { ResearchButton } from "../Button/ResearchButton.js";
import { Programs } from "../Programs/Programs.js";
import { ContactHome } from "../Contact/ContactHome/ContactHome.js";
import { Highlights } from "../Highlights/Highlights.js";
import { Logos } from "../Logos/Logos.js";
import { MyCarousel } from "../MyCarousel/MyCarousel.js";
import { LinkedInPosts } from "../LinkedInPosts/LinkedInPosts.js";
import { CarouselPub } from "../MyCarousel/CarouselPub.js";
import { HighlightsHome } from "../HighlightsHome/HighlightsHome.js";
import {Embed} from "../LinkedInPosts/Embed.js";
import { useMediaQuery } from "usehooks-ts";
import {Tools} from "../Tools/Tools.js";
import {Featured_tools} from "../Tools/Featured_tools.js";


export function Home() {
  const isMobile2 = useMediaQuery("(max-width: 992px)");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const VideoModal = () => (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Video</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="video-container">
          <iframe
            width="100%"
            height="400"
            src="https://www.youtube.com/embed/uQtDc797tf8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      {isMobile2 ? (
        // Mobile version content
        <>
          <section className="cuttingEdgeWrapper">
            <div className="cuttingEdgeCont">
              <h2 className="cuttingEdge">Cutting Edge Research</h2>
              <div className="cuttingEdgeButton">
                <ResearchButton />
              </div>
            </div>
           
          </section>
          <div className="home">
            <Row className="about sec1">
              <Col xs={12}>
                <header className="headerMolec">
                  <h3>
                    <span className="minkara">MINKARA</span>{" "}
                    <span className="combine">COMBINE</span>{" "}
                    <span className="minkara">LAB</span>
                  </h3>
                  <h4 className="molec">Molecular Detectives</h4>
                </header>
                <p>
                  The Computational Modeling for Biointerface Engineering Laboratory (COMBINE Lab) at Northeastern University utilizes computational techniques to model complex biological interfaces at the molecular level, with the ultimate goal of engineering new therapeutics in the field of bioengineering.
                </p>
              </Col>
              <>
              <Row xs={12} md={6} className="homeVid">
                <div onClick={handleShow} className="vidCombineLink" style={{ cursor: 'pointer' }}>
                  <Image
                    src="/images/HomePics/introCombineVid.jpeg"
                    className="img-fluid vidCombine"
                    alt="Mona sits at a desk in her office."
                  />
                  <i className="bi bi-play-circle playHome"></i>
                </div>
              </Row>

              <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                  <Modal.Title>Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="video-container">
                    <iframe
                      width="100%"
                      height="400"
                      src="https://www.youtube.com/embed/uQtDc797tf8"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
            </Row>
            <section className="sec-bg">
              <Programs />
            </section>

            <section className="toolsSecCont">
              <header className="toolsSec headerContPI">
                <h3 className="redHeaderHome">Featured IN</h3>
              </header>
              <Logos />
            </section>

            <section className="toolsSecCont">
              <Row>
                <Col xs={12} md={6}>
                  <header className="toolsSec">
                    <h3 className="redHeaderHome">Public Impact</h3>
                  </header>
                </Col>
                
              </Row>
              <Row>
                  <MyCarousel />
              </Row>
              <Row className="project-highlight">
                  <header className="toolsSec">
                    <h3 className="redHeaderHome">LinkedIn</h3>
                  </header>
                  <Row xs={12} md={6} className="tools-col">
                  <Embed />
                  </Row>
               </Row>
                <Row className="project-highlight">
                  <header className="toolsSec">
                    <h3 className="redHeaderHome text-right">Project Highlight</h3>
                  </header>
                <Row xs={12} md={6} className="tools-col">
                  <Tools />
                </Row>
              </Row>
            </section>
          </div>
        </>
      ) : (
        // Desktop version content
        <>
          <section className="cuttingEdgeWrapper">
            <div className="cuttingEdgeCont">
              <h2 className="cuttingEdge">Cutting Edge Research</h2>
              <div className="cuttingEdgeButton">
                <ResearchButton />
              </div>
            </div>
           
          </section>

          {/* Common content for desktop */}
          <div className="home">
            <Row className="about sec1">
              <Col xs={12} md={6}>
                <header className="headerMolec">
                  <h3>
                    <span className="minkara">MINKARA</span>{" "}
                    <span className="combine">COMBINE</span>{" "}
                    <span className="minkara">LAB</span>
                  </h3>
                  <h4 className="molec">Molecular Detectives</h4>
                </header>
                <p>
                  The Computational Modeling for Biointerface Engineering Laboratory (COMBINE Lab) at Northeastern University utilizes computational techniques to model complex biological interfaces at the molecular level, with the ultimate goal of engineering new therapeutics in the field of bioengineering.
                </p>
              </Col>
              <Col xs={12} md={6} className="homeVid">
              <div onClick={handleShow} className="vidCombineLink" style={{ cursor: 'pointer' }}>
                  <Image
                    src="/images/HomePics/introCombineVid.jpeg"
                    className="img-fluid vidCombine"
                    alt="Mona sits at a desk in her office."
                  />
                  <i className="bi bi-play-circle playHome"></i>
                </div>
              </Col>

              <VideoModal />
            </Row>

            <section className="sec-bg">
              <Programs />
            </section>

            <section className="toolsSecCont">
              <header className="toolsSec headerContPI">
                <h3 className="redHeaderHome">Featured IN</h3>
              </header>
              <Logos />
            </section>

            <section className="toolsSecCont">
              <Row>
                <Col xs={12} md={6}>
                  <header className="toolsSec">
                    <h3 className="redHeaderHome">Public Impact</h3>
                  </header>
                </Col>
                <Col xs={12} md={6} className="project-highlight">
                  <header className="toolsSec">
                    <h3 className="redHeaderHome text-right">Project Highlight</h3>
                  </header>
                </Col>
              </Row>
              <Row className="last_row">
                <Col xs={12} md={6}>
                  <MyCarousel />
                </Col>
                <Col xs={12} md={6}>
                  <Tools />
                </Col>
                <Col xs={12} md={6} className="tools-col">
                <header className="toolsSec">
                    <h3 className="redHeaderHome">LinkedIn</h3>
                  </header>
                  <Embed />
                  
                </Col>
                <Col  xs={12} md={6} className="tools-col-end">
                  <header className="toolsSec">
                    <h3 className="redHeaderHome">FEATURED TOOLS</h3>
                  </header>
                  <Featured_tools />
                </Col>
              </Row>
            </section>
          </div>
        </>
      )}
    </div>
  );
}
