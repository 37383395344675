import { Route, Routes, Outlet } from "react-router-dom";
import { Home } from "./Components/Home/Home.js";
import { Publications2 } from "./Components/Publications/Publications2.js";
import { Contact } from "./Components/Contact/Contact.js";
import { Research } from "./Components/Research/Research.js";
import { Research2 } from "./Components/Research/Research2.js";
import { Group } from "./Components/Group/Group.js";
import {News} from "./Components/News/News.js";
import {Apply} from "./Components/Apply/Apply.js";
import { PrivacyPolicy } from "./Components/PrivacyPolicy/PrivacyPolicy.js";

export function Routing() {
  return (
    //check to see when /Home is applied and if needed
    //should Home be displayed in url when Home is rendered
    <Routes>
      <Route path="/" element={<Home></Home>} />
      <Route path="/Home" element={<Home></Home>} />
      <Route index element={<Home></Home>} />
      <Route path="/Research" element={<Research2></Research2>} />
      <Route path="/Group" element={<Group></Group>} />
      <Route path="/Publications" element={<Publications2></Publications2>} />
      <Route path="/News" element={<News></News>} />
      <Route path="/Contact" element={<Contact></Contact>} />
      <Route path="/Apply" element={<Apply></Apply>} />
      <Route path="/PrivacyPolicy" element={<PrivacyPolicy></PrivacyPolicy>} />
    </Routes>
  );
}
