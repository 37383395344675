import { Row, Col, Card, Image } from "../../bootstrap.js";
import { LinkButtonLight } from "../Button/Link/LinkButtonLight.js";
import "./tools.css";

export function Featured_tools() {
  
    return (
      <div className="toolsRow3D">
  <Row className="toolRow1">
    <Col xs={6} sm={4} md={3} className="feattoolsDescription1">
      <div class="shiny-row">
      <Row className="full_width">
        <Col className="aligntoolname">
        <Row className="Whitebg">
        <a className="featToolLink" href="https://monaminkara.com/hardware/#braillesense">BRAILLE  NOTETAKER</a>
        </Row>
        </Col>
        
      <Col className="aligntoolimg">
      <img
                src="../images/braillesense.jpeg"
                alt="A molecule is held by hands"
                className="Handmole"
              />
      </Col>
        </Row>  
     
      </div>
      <div class="shiny-row">
      <Row className="full_width">
        <Col  className="aligntoolname">
        <Row className="Whitebg">
        <a className="featToolLink" href="https://monaminkara.com/learning-concepts/#swell-forms">SWELL  FORM</a>
        </Row>
        </Col>
      <Col className="aligntoolimg">
      <img
                src="../images/swell-form.jpeg"
                alt="A molecule is held by hands"
                className="Handmole"
              />
      </Col>
        </Row>  
      
      </div>
      <div class="shiny-row">
      <Row className="full_width">
        <Col  className="aligntoolname">
        <Row className="Whitebg">
        <a className="featToolLink" href="https://monaminkara.com/learning-concepts/#lithophanes">LITHOPHANES</a></Row>
        </Col>
      <Col className="aligntoolimg">
      <img
                src="../images/lithophanes.png"
                alt="A molecule is held by hands"
                className="Handmole"
              />
      </Col>
        </Row>  
      
      </div>
      <div class="shiny-row">
      <Row className="full_width">
        <Col  className="aligntoolname" >
        <Row className="Whitebg">
        <a className="featToolLink" href="https://monaminkara.com/learning-concepts/#lithophanes">MOLECULAR MODELING KIT</a></Row>
        </Col>
      <Col className="aligntoolimg">
      <img
                src="../images/HomePics/hand_molecule.jpeg"
                alt="A molecule is held by hands"
                className="Handmole"
              />
      </Col>
        </Row>  
      
     
      </div>
    </Col>
  </Row>
</div>
    );
  }