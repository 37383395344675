// import { Testimonials } from "./Testimonials/Testimonials.js";
import { ContactForm } from "./ContactForm";
import { Row, Col, Card, Image } from "../../bootstrap.js";
// import { CardHeader } from "../CardPTC/CardHeader.js";
import "./contact.css";
import { HeroImage2 } from "../HeroImage/HeroImage2.js";

export function Contact() {
  return (
    <>
    <div className="contactHero">
    <header>
          <h3 className="contactTopHeader">Contact</h3>
        </header>
        <Image src="/images/Contact/contactBG.jpg" className="img-fluid contactHeroImg"  alt="Building of ISEC center at night."></Image>
    {/* <HeroImage2 title="Contact" imgUrl="contactCoverPage3.png" alt="Building of ISEC center at night."></HeroImage2> */}
    </div>
      <div className="contact">
        <Col>
          <ContactForm></ContactForm>
        </Col>
        {/* </Row> */}
      </div>
    </>
  );
}
