import { Row, Col, Card, Image } from "../../bootstrap.js";
import { LinkButtonLight } from "../Button/Link/LinkButtonLight.js";
import "./tools.css";

export function Tools() {
  
    return (
      <div className="toolsRow3D">
  <Row className="toolRow1">
    <Col xs={6} sm={4} md={3} className="toolsDescription1">
      <header>
        <h4 className="toolsHeader">Blind Scientist Tools</h4>
      </header>
      <p className="font">
        Explore Blind Scientist Tools, a comprehensive web repository of multi-sensory resources designed for scientific research. 
        This includes software, hardware, and innovative techniques tailored for blind scientists. From screen readers to tactile graphics, these tools supported my academic journey from student to bioengineering professor. Visit Blind Scientist Tools to learn more.
      </p>
      <div className="Link_button">
        <LinkButtonLight href="https://monaminkara.com/blind-scientist-tools" />
      </div>
    </Col>
  </Row>
</div>
    );
  }

// The Blind Scientist Toolkit is a freely accessible web repository that
//           provides guidance on software, hardware and human resources needed to
//           give blind and partially sighted scientists access to conducting
//           scientific research.
//           <br></br>
//           <br></br>
//           The resource is inspired by Dr. Mona's journey conducting research as
//           a blind scientist. For example, tools like Graffiti, Swell Form, and Dr. Mona's
//           invention of lithophanese have been essential in her research
//           on collectins.
