
import { Row, Col, Card, Image } from "../../bootstrap.js";
import "./embed.css";
import { useMediaQuery } from "usehooks-ts";

export function Embed(){
    const isMobile2 = useMediaQuery("(max-width: 992px)");
    return (
        isMobile2 ? (
    
    <div className="linkedInCont">
        {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7204186675504181249" height="410" width="700" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}
        <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7204211703318757376" title="Embedded post" className="embed"></iframe>
        </div>
        ):(
            <div className="toolsRow3D">
            <div class="shiny-row2">
           
             {/* <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7204186675504181249" height="410" width="700" frameborder="0" allowfullscreen="" title="Embedded post"></iframe> */}
             <iframe src="https://www.linkedin.com/embed/feed/update/urn:li:share:7204211703318757376"  frameborder="0" allowfullscreen="" title="Embedded post" className="embed"></iframe>
            </div>
            </div>
        )
    )}


