import { Row, Col, Card, Image, Ratio } from "../../bootstrap.js";
import "./pdfViewer.css";

export function PDFViewer({ title, file, styling }) {


  return (
    <div className={`aspect-ratio-box ${styling}`}>
  <iframe title={title} src={`/files/${file}.pdf`} />
</div>
  );
}
